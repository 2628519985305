import { NextPage } from 'next';
import React from 'react';

import Page404Content from 'components/Page404Content/Page404Content';

const Custom404: NextPage = () => {
  return <Page404Content />;
};

export default Custom404;
